/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AssemblyHeaderRow,
  BomItemType,
  MaterialHeaderRow,
} from 'model/Project/BoMItemRow'
import { ProjectModel } from 'model/ProjectModel'
import {
  GetAvailableDeliveryDaysResponse,
  IssueCode,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { BomItemPointer } from '../../../model/Project/BomItemPointer'
import { normalizeGetProjectResponse } from '../../../store/Project/normalizeGetProjectResponse'
import { isTheProjectLocked as getProjectCurrentStatus } from '../../../store/Project/ProjectStateUtils'
import { fetchBomItem } from './asyncActions/fetchBomItem'
import { fetchProject } from './asyncActions/fetchProject'
import { subscribeToProjectNotifications } from './asyncActions/subscribeToUpdates'
import { addActivityToBomItem } from './bomItem/addWorkingStepToBomItem'
import { bomItemActivityReplacer } from './bomItem/bomItemActivityReplacer'
import {
  activeProjectReplacer,
  bomItemReplacer,
  projectStateReplacer,
} from './bomItem/bomItemReplacer'
import { bomItemWorkingStepsReplacer } from './bomItem/bomItemWorkingStepsReplacer'
import { deleteBomItemFromStore } from './bomItem/deleteBomItemFromStore'
import { removePricesFromAllBoMItems } from './bomItem/removePricesFromAllBoMItems'
import { removeWorkingStepFromBomItem } from './bomItem/removeWorkingStepFromBomItem'
import { resetBomItemFinancials } from './bomItem/resetBomItemFinancials'
import { setBoMItemFocus } from './bomItem/setBoMItemFocus'
import { openOrCloseAllHeaders, setBoMItemOpen } from './bomItem/setBoMItemOpen'
import {
  setSearchedBomItem,
  setSearchedBomItemByRowNumber,
} from './bomItem/setSearchedBomItem'
import { setWorkingStepProductionOrder } from './bomItem/setWorkingStepProductionOrder'
import { removeAllFilters } from './filterActions/removeAllFilters'
import { setFilterProperty } from './filterActions/setFilterProperty'
import { toggleActiveFilter } from './filterActions/toggleActiveFilter'
import { refreshIssuesFilter } from './filters/refreshIssuesFilter'
import { refreshMaterialFilter } from './filters/refreshMaterialFilter'
import { refreshTodosFilter } from './filters/refreshTodosFilter'
import { refreshWorkingStepFilter } from './filters/refreshWorkingStepFilter'
import { updateHeadersFilteredParts } from './filters/updateHeadersFilteredParts'
import {
  changeProjectView,
  changeProjectViewIfInView,
} from './project/changeProjectView'
import { setOperationProgress } from './project/setOperationProgress'
import { setWaitingForNesting } from './project/setWaitingForNesting'
import { updateOperationsFlags } from './project/updateOperationsFlags'
import { projectInitialState } from './ProjectInitialState'
import { registerAsyncOperationStatus } from './registerAsyncOperationStatus'
import { BomItemSelectorFn } from './selectors/bomItemSelector'

export function getActionPrefix(action: AnyAction, aditionalSuffix?: string) {
  let actionName: string = action.type.substring(
    0,
    action.type.lastIndexOf('/')
  )

  if (aditionalSuffix) {
    actionName = actionName.concat('/', aditionalSuffix)
  }

  return actionName
}

const projectSlice = createSlice({
  name: 'project',
  initialState: projectInitialState,
  reducers: {
    unloadProject: () => {
      return projectInitialState
    },
    saveProject: (state, action: PayloadAction<ProjectModel>) => {
      state.activeProject = {
        ...action.payload,
        id: action.payload.id || '',
        type: BomItemType.project,
      }
    },
    setBomItemProperties: bomItemReplacer,
    resetBomItemFinancials: resetBomItemFinancials,
    setBomItemWorkingSteps: bomItemWorkingStepsReplacer,
    setBomItemActivity: bomItemActivityReplacer,
    addWorkingStep: addActivityToBomItem,
    setWorkingStepProductionOrder,
    removeWorkingStep: removeWorkingStepFromBomItem,
    setActiveProjectProperties: activeProjectReplacer,
    setProjectStateProperties: projectStateReplacer,
    setBoMItemOpen: setBoMItemOpen,
    setBomItemFocus: setBoMItemFocus,
    setBomItemInSearch: setSearchedBomItem,
    setBomItemInSearchByRowNumber: setSearchedBomItemByRowNumber,
    removeBomItemIssue: (
      state,
      action: PayloadAction<{
        bomItemPointer: BomItemPointer
        issueCode: IssueCode
      }>
    ) => {
      if (!state.activeProject) {
        return
      }

      const bomItem = BomItemSelectorFn(action.payload.bomItemPointer)({
        project: state,
      })

      if (bomItem.type === BomItemType.partType) {
        bomItem.issues = bomItem.issues.filter(
          (issue) => issue.issueCode !== action.payload.issueCode
        )
      }
    },
    setSelectedBomItems: (
      state,
      action: PayloadAction<Array<BomItemPointer>>
    ) => {
      state.selectedBomItemPointers = action.payload
    },
    setSelectedHeaderIds: (state, action: PayloadAction<BomItemPointer[]>) => {
      state.selectedHeaderPointers = action.payload
    },
    removeFromSelectedHeaderIds: (
      state,
      action: PayloadAction<BomItemPointer>
    ) => {
      state.selectedHeaderPointers = state.selectedHeaderPointers.filter(
        (pointer) => pointer.id !== action.payload.id
      )
    },
    removeFromSelectedBomItems: (
      state,
      action: PayloadAction<BomItemPointer>
    ) => {
      state.selectedBomItemPointers = state.selectedBomItemPointers.filter(
        (pointer) => pointer.id !== action.payload.id
      )
    },
    removeAsSelectedBomItems: (
      state,
      action: PayloadAction<BomItemPointer[]>
    ) => {
      state.selectedBomItemPointers = state.selectedBomItemPointers.filter(
        (pointer) => !action.payload.map((x) => x.id).includes(pointer.id)
      )
    },

    clearSelectedBomItems: (state) => {
      state.selectedBomItemPointers = []
    },
    includeInSelectedBomItems: (
      state,
      action: PayloadAction<BomItemPointer>
    ) => {
      let bomItemPointersToSelect: BomItemPointer[] = []
      if (
        action.payload.type === BomItemType.assemblyType ||
        action.payload.type === BomItemType.materialHeader
      ) {
        const filteredPartTypes = BomItemSelectorFn<
          AssemblyHeaderRow | MaterialHeaderRow
        >(action.payload)({ project: state })?.filteredPartTypePointers

        bomItemPointersToSelect = filteredPartTypes
      } else {
        bomItemPointersToSelect = [action.payload]
      }

      bomItemPointersToSelect.forEach((bomItemPointer) => {
        if (
          state.selectedBomItemPointers.some((x) => x.id === bomItemPointer.id)
        ) {
          state.selectedBomItemPointers = state.selectedBomItemPointers.filter(
            (x) => x.id !== bomItemPointer.id
          )
        } else {
          state.selectedBomItemPointers.push(bomItemPointer)
        }
      })

      return state
    },
    includeAsSelected: (
      state,
      action: PayloadAction<Array<BomItemPointer>>
    ) => {
      state.selectedBomItemPointers = state.selectedBomItemPointers.concat(
        action.payload
      )
    },
    updateHeadersFilteredParts: updateHeadersFilteredParts,
    refreshWorkingStepFilter: refreshWorkingStepFilter,
    refreshMaterialFilter: refreshMaterialFilter,
    refreshIssuesFilter: refreshIssuesFilter,
    removePrices: removePricesFromAllBoMItems,
    openOrCloseAllHeaders: openOrCloseAllHeaders,
    changeProjectView: changeProjectView,
    changeProjectViewIfInView: changeProjectViewIfInView,
    uploadProgress: setOperationProgress,
    deleteBomItem: deleteBomItemFromStore,
    toggleActiveFilter: toggleActiveFilter,
    setFilter: setFilterProperty,
    removeAllFilters: removeAllFilters,
    updateOperationsFlags: updateOperationsFlags,
    setWaitingForNesting: setWaitingForNesting,
    showAllComments: (state, action: PayloadAction<boolean>) => {
      state.showAllComments = action.payload
    },
    showAllIssues: (state, action: PayloadAction<boolean>) => {
      state.showAllIssues = action.payload
    },
    setAvailableDeliveryDays: (
      state,
      action: PayloadAction<GetAvailableDeliveryDaysResponse['noDeliveryDate']>
    ) => {
      state.noDeliveryDates = action.payload

      return state
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      if (!action.payload) {
        return state
      }

      // users may not be in the page when the api response is received
      // so we need to check if the current url contains the received projectId
      // if not, we ignore the response
      const currentUrl = window.location.href
      const projectId = action.payload.id
      if (!currentUrl.includes(projectId)) {
        console.warn(
          '(reducer): received project id is different than the one in the URL. Ignoring the api response',
          {
            currentUrl,
            projectId,
          }
        )
        return state
      }

      if (
        state.activeProject?.id &&
        action.payload['id'] !== state.activeProject?.id
      ) {
        // case the received id is different that the current project id, ignore it and return
        // the current state
        console.warn(
          'received project id is different than the current one, ignoring api response. expected to change to correct one',
          {
            current: state.activeProject?.id,
            received: action.payload['id'],
          }
        )
        // return state
      }
      state = {
        ...state,
        ...normalizeGetProjectResponse(
          action.payload,
          state,
          state.selectedBomItemPointers[0]?.id
        ),
      }

      const { projectIsLocked, projectStatus } = getProjectCurrentStatus(
        state.activeProject
      )

      state.activeProject.status = projectStatus
      state.activeProject.isLocked = projectIsLocked

      state = updateHeadersFilteredParts(state)

      state = refreshWorkingStepFilter(state)
      state = refreshMaterialFilter(state)
      state = refreshIssuesFilter(state)
      state = refreshTodosFilter(state)

      return state
    })
    builder.addCase(fetchBomItem.fulfilled, (state, action) => {
      bomItemReplacer(state, {
        ...action,
        payload: {
          bomItemPointer: {
            id: action.payload.id,
            type: action.payload.type,
          },
          properties: action.payload,
        },
      })
    })
    builder.addCase(subscribeToProjectNotifications.fulfilled, () => {
      console.info('subscribed to updates')
    })

    registerAsyncOperationStatus(builder)
  },
})

export default projectSlice.reducer
export const newProjectActions = projectSlice.actions
