import {
  AuthorizationParams,
  LogoutOptions,
  useAuth0,
} from '@auth0/auth0-react'
import { useTheme } from '@mui/material'
import { useClientStorage } from 'customHooks/useClientStorage'
import { isEqual } from 'lodash'
import queryString from 'query-string'
import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/configureStore'

function testPopupBlocker(): boolean {
  const popup = window.open('', '', 'width=0,height=0')
  if (!popup) {
    return true
  }
  popup.close()

  return false
}

/**
 * use this when you don't have access to useAppLogin hook
 */
export const ClearLoginTries = () => {
  try {
    localStorage.removeItem('triedLoginWithRedirection')
    localStorage.removeItem('loginWithRedirectReturnUrl')
    sessionStorage.removeItem('triedLoginWithRedirection')
    sessionStorage.removeItem('hasPopupBlocker')
    sessionStorage.removeItem('loginWithRedirectReturnUrl')
  } catch (err) {
    console.error('unable to clear login tries', err)
  }
}

export const useAppLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    loginWithPopup,
    logout,
    error,
  } = useAuth0()
  const theme = useTheme()

  const tenantConfiguration = useAppSelector((state) => {
    return state.tenant
  }, isEqual)

  const { email } = queryString.parse(window.location.search)
  const returnTo = window.location.search.split('returnTo=')[1]

  const [hasPopupBlocker, setHasPopupBlocker] = useClientStorage(
    'hasPopupBlocker',
    false,
    'sessionStorage'
  )

  const [triedLoginWithRedirection, setTriedLoginWithRedirection] =
    useClientStorage('triedLoginWithRedirection', false, 'sessionStorage')

  const [returnUrl, setReturnUrl] = useClientStorage(
    'loginWithRedirectReturnUrl',
    undefined,
    'sessionStorage'
  )

  useEffect(() => {
    if (!returnUrl && returnUrl !== '/app') {
      setReturnUrl(returnTo || location.state?.returnUrl)
    }
  }, [location.state?.returnUrl, returnTo, returnUrl, setReturnUrl])

  const clearLoginTries = useCallback(() => {
    setTriedLoginWithRedirection(false, true)
    setHasPopupBlocker(false, true)
    setReturnUrl(undefined, true)
  }, [setReturnUrl, setTriedLoginWithRedirection, setHasPopupBlocker])

  useEffect(() => {
    const clearLoginTriesOnUnload = () => {
      if (window.self === window.parent) {
        // in the iframe we don't want to clear the login tries
        clearLoginTries()
      }
    }

    window.addEventListener('beforeunload', clearLoginTriesOnUnload)

    return () => {
      window.removeEventListener('beforeunload', clearLoginTriesOnUnload)
    }
  }, [clearLoginTries])

  const appLoginWithRedirection = useCallback(async () => {
    setTriedLoginWithRedirection(true, true)
    setReturnUrl(returnTo || location.state?.returnUrl || '/app', true)

    const appStateBeforeRedirect = {
      ...(location.state || {}),
      returnUrl: returnTo || '/app',
      tenantConfiguration,
    }

    console.info('appStateBeforeRedirect', appStateBeforeRedirect)

    await loginWithRedirect({
      authorizationParams: {
        display: 'page',
        login_hint: email ? decodeURIComponent(email?.toString()) : undefined,
        logo: theme.logoSrc,
        primaryColor: theme.palette.primary.main,
        page_background: theme.palette.background.default,
        backgroundImage: theme.backgroundImageSrc,
        forgotPasswordLink: `${window.location.origin}/auth/forgot-password`,
        redirect_uri: window.location.origin + '/auth/callback',
      },
      appState: appStateBeforeRedirect,
    })
  }, [
    email,
    loginWithRedirect,
    returnTo,
    setReturnUrl,
    setTriedLoginWithRedirection,
    tenantConfiguration,
    theme.backgroundImageSrc,
    theme.logoSrc,
    theme.palette.background.default,
    theme.palette.primary.main,
    location.state,
  ])

  useEffect(() => {
    if (triedLoginWithRedirection) {
      if (
        location.pathname.includes('auth') ||
        location.pathname.includes('login')
      ) {
        setTimeout(() => {
          const hasBlocker = testPopupBlocker()

          console.info('hasBlocker', hasBlocker)
          setHasPopupBlocker(hasBlocker)
        }, 200)
      }
    }
  }, [location.pathname, setHasPopupBlocker, triedLoginWithRedirection])

  async function appLogout(options?: LogoutOptions) {
    clearLoginTries()
    window.localStorage.removeItem('rh24_lastVisitedWorkspaceId')
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout`,
      },
      clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
      ...(options || {}),
    })
  }

  const createPopup = () => {
    const width = 600
    const height = 600
    const left = window.screenX + (window.innerWidth - width) / 2
    const top = window.screenY + (window.innerHeight - height) / 2
    const popup = window.self.open(
      '',
      '',
      `left=${left},top=${top},width=${width},height=${height},toolbar=0,scrollbars=yes,status=0,popup`
    )

    return popup
  }

  const appLoginWithPopup = useCallback(
    async (options?: {
      screen_hint?: 'login' | 'signUp' | 'forgot-password'
      emailHint?: string
      disableRedirection?: boolean
      prefill?: {
        email: string
        given_name: string
        family_name: string
      }
    }) => {
      let loginHint = undefined

      const popup = createPopup()
      if (!popup) {
        return
      }

      if (options?.emailHint) {
        loginHint = options.emailHint
      } else if (Boolean(email)) {
        loginHint = decodeURIComponent(email?.toString())
      }

      try {
        await loginWithPopup(
          {
            authorizationParams: {
              display: 'page',
              login_hint: loginHint,
              screen_hint: options?.screen_hint || 'login',
              logo: theme.logoSrc,
              primaryColor: theme.palette.primary.main,
              page_background: theme.palette.background.default,
              backgroundImage: theme.backgroundImageSrc,
              forgotPasswordLink: `${window.location.origin}/auth/forgot-password`,
              redirect_uri: window.location.origin + '/auth/callback',
              prefill: options?.prefill
                ? JSON.stringify(options.prefill)
                : undefined,
            } as AuthorizationParams,
          },
          {
            popup: popup,
          }
        )
        if (!options?.disableRedirection) {
          navigate(returnUrl || '/app')
        }
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    [
      email,
      loginWithPopup,
      navigate,
      returnUrl,
      theme.backgroundImageSrc,
      theme.logoSrc,
      theme.palette.background.default,
      theme.palette.primary.main,
    ]
  )

  return {
    triedLoginWithRedirection,
    setTriedLoginWithRedirection,
    returnUrl,
    clearLoginTries,
    isAuthenticated,
    isLoading,
    appLoginWithRedirection,
    appLoginWithPopup,
    error,
    appLogout,
    logoSrc: theme.logoSrc,
    hasPopupBlocker,
  }
}
